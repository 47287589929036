.aboutSection {
    display: flex;
    min-height:100vh;
    width:100vw;
    align-items: center;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    justify-content: center;
    background-color: var(--main-blue);
    text-align:center;
}
.aboutHeader {
    flex-wrap: nowrap;
}

.aboutSection h1 {
    text-align: center;
}

.aboutContainers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

}
.aboutContainer {
    padding:5%;
    display:flex;
    flex-direction:row;
    justify-content: center;
}

.aboutImage {
    width:80%;
    border-radius: 10rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.imageDiv {
    padding:4rem;
}

.aboutDesc {
    padding-top: 15%;
    width:100%;
    flex-wrap:nowrap;
    text-align:start;
}

.aboutDesc b {
    font-weight:bolder;
    color: var(--main-purple)
}

@media only screen and (max-width: 1100px){
    .aboutContainer {
        padding:5%;
        display:flexbox;
        flex-direction:column;
        justify-content: center;
    }
}
