.contactMeContainer {
    display: flex;
    /* min-height:100vh;
    width:100vw; */
    align-items: center;
    flex-direction: column;
    padding:10vw;
    justify-content: center;
}

.contactMeText {
    padding: 10rem;
}

.contactMeText a {
    padding: 1rem;
}

.contactMeText a :hover {
    color:var(--main-white);
}

.contactMeHeading {
    border-color: transparent;
}

.socials {
    width:100%;
    list-style-type: none; 
    justify-content: center;
    align-items: center;
    display: flex;
    padding:1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 3rem;
}

.socials li {
    padding: 5%;
    float: left;
}

.socials a {
    font-size: 4rem;
}

.socials a :hover {
    color:var(--main-white);
}
.text {
    padding: 5%;
}

@media only screen and (max-width: 1100px){
    .socials {
        width:100%;
        list-style-type: none; 
        justify-content: center;
        align-items: center;
        display: block;
        padding:1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 3rem;
    }

}