* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: roboto-flex;
    font-weight: 100;
    color: var(--main-dark-blue);
    background-color: var(--main-blue);
}

html {
    scroll-behavior: smooth;
}

:root{
    --main-baby-blue: #e5f8f8;
    --main-dark-blue: #5c92a6;
    --main-blue-accent: #a8cad6;
    --main-blue: #e1f0ff;
    --main-light-blue: #cbf2f5;
    --main-green: #eef6f2;
    --main-dark-green: #8bcfd3;
    --main-turq:#ecfefb;
    --main-purple: #e1a0f7;
    --main-white:#ffffff;
}


@font-face {
    font-family: proximanova-light;
    src: url('../fonts/Fontspring-DEMO-proximanova-light.otf') format('opentype'), /* Modern Browsers */
}

@font-face {
    font-family: proximanova-medium;
    src: url('../fonts/Fontspring-DEMO-proximanova-medium.otf') format('opentype'), /* Modern Browsers */
}

@font-face {
    font-family: proximanova-cond;
    src: url('../fonts/Fontspring-DEMO-proximanovacond-light.otf') format('opentype'), /* Modern Browsers */
}

@font-face {
    font-family: roboto-flex;
    src: url('../fonts/RobotoFlex-Regular.ttf') format('truetype'), /* Modern Browsers */
}


h1 {
    font-size: 4rem;
    color: var(--main-dark-green);
    padding:1rem;
    background-color: var(--main-turq);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
}

h2 {
    font-size: 5rem;
}
h3 {
    font-size: 3rem;
    font-family: roboto-flex;
}
h4 {
    font-size: 3rem;
}
p {
    font-size: 1.2rem;
}
em {
    color: var(--main-dark);
    font-weight:10px;
}
a {
    font-size: 2rem;
}