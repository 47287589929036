.homeContainer {
    background-color: var(--main-blue);
    display: flex;
    justify-content: center;
    min-height:100vh;
    width:100vw;
    flex-direction: column;
    text-align: center;
}

.homeText {
    padding:20%;
}

.titleHeading {
    display:flex;
    justify-content:center;
    align-items:center;
    gap: 1rem;
}

.titleSubheading2 {
    text-align: center;
}

@media only screen and (max-width: 900px){
    .homeText {
        padding-left: 5%;
        padding-right: 5%;
    }

    .ScrollWord {
        font-size: 2rem;
    }
}