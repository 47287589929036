.timelineContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--main-blue);
    padding-bottom:5%;
    padding-top:10%;
}

.timelineElem {
    position: relative;
    display: flex;
    margin: 2%;
}

.timelineIcon {
    font-size: 3rem;
    padding-right: 2rem;
    background-color: transparent;
}

.largeScreenIcon {
    display: flex;
    position: relative;
}

.timelineDate {
    width: 8rem;
}

.mobileDate {
    display: none;
}

.timelineElementContent {
    margin-top: 2rem;
    margin-left:1rem;
    padding: 2rem;
    width: 500px;
    height:320px;
    border-radius: 3rem;
    background-color: var(--main-green);
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}

.elementTitle {
    font-size: 1.5rem;
    font-weight: 1000;
    background-color: var(--main-green);
}

.elementLocation {
    font-size: .9rem;
    margin-top: 2%;
    margin-bottom: 5%;
    background-color: var(--main-green);
}

.elementDesc {
    font-size: 1.2rem;
    margin-bottom: 3%;
    background-color: var(--main-green);
}

.elementTech {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    background-color: transparent;
}

.eachElementTech {
    margin:5px;
    flex-wrap: nowrap;
    background-color: var(--main-light-blue);
    padding: 10px;
    font-size: 1rem;
    border-radius: 1rem;
    margin-left:1%;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: var(--main-blue-accent);
}

.verticalLine {
    position: relative;
    width:5px;
    background-color: var(--main-blue-accent);
    height: 350px;
    transform: translate(155px,60px);
}

.timelineImage {
    margin-top: 3rem;
    width: 300px;
    height:300px;
    justify-content: center;
}

.appianLogo {
    width:100%;
    height:100%;
    border-radius: 3rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}