.projectContainer {
    display: flex;
    min-height:100vh;
    width:100vw;
    align-items: center;
    flex-direction: column;
    padding:10vw;
    justify-content: center;
}

.projectGrid {
    margin-top:6rem;
    display: flex;
    justify-content: space-around;
    gap:3rem;
    flex-wrap: wrap;
}

.projectElement {
    padding-top: 3%;
    display: flex;
    height: 33rem;
    width: 24rem;
    background-color:var(--main-turq);
    border-radius: 2rem;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 15px;
    border: rgb(53, 53, 53) 0.4rem solid;
    border-color: var(--main-blue-accent);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.projectImageDiv {
    height: 16rem;
    width:20rem;
    background-color: transparent;
}

.projectImage {
    height:100%;
    width:100%;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: var(--main-blue-accent);
    object-fit: cover;
}

.projectImageExpanded {
    height:100%;
    width:100%;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: var(--main-blue-accent);
    object-fit: cover;
}

.projectName {
    background-color: transparent;
}

.projectInfo {
    background-color: transparent;
    text-align:center;
}

.elementProjectTech {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    background-color: transparent;
}

.eachElementProjectTech {
    margin:5px;
    flex-wrap: nowrap;
    background-color: var(--main-light-blue);
    padding: 10px;
    font-size: 1rem;
    border-radius: 1rem;
    margin-left:1%;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: var(--main-blue-accent);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.githubLink {
    font-size: 2rem;
    background-color: transparent;
}

.githubIcon {
    background-color: transparent;
}

.githubIcon :hover {
    color:var(--main-purple);
}

.projectExpandedView {
    position: relative;

    z-index:10;
    border-radius: 2rem;
    border: rgb(53, 53, 53) 0.1rem solid;
    border-color: var(--main-blue-accent);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.projectExpandedViewText {
    display:flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 2rem;
    padding: 5%;
    align-items:center;
}

.project2ndImageDiv {
    background-color: transparent;
    height: 350px;
    width:400px;
    display: flex;
    justify-content: center;

}

.projectTitle {
    padding: 10px;
}

.projectContainer h1 {
    padding: 2rem;
    
}