header {
    display: flex;
    position: absolute;
    top:0;
    left:0;
    right:0;
    align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
    color: var(--main-dark-blue);
    background-color: var(--main-blue);
    align-items:baseline;
}

header .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 2rem;
    color: var(--main-dark-blue);
}

.nav {
    background-color: var(--main-blue);
    display: flex;
    align-items: center;
}

.site-title {
    font-size:2rem;
    font-family: roboto-flex;
}

.site-title:hover {
    background-color: #777;
}

header ul {
    padding: 1rem;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 4rem;
    font-size: 2rem;
}

header a {
    color: inherit;
    text-decoration: none;
    padding:1rem;
    white-space: nowrap;
    border-radius: 20px;
}

.nav a:hover {
    background-color: var(--main-blue-accent);
}

.scrollingNav {
    display:flex;
    position:fixed;
    top:0;
    right:0;
    left:0;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    background-color: transparent;
    z-index: 999;
}

.scrollingNav ul {
    padding: 1rem;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 3rem;
    font-size: 1rem;
    background: var(--main-baby-blue);
    border-radius: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.scrollingNav li {
    background: var(--main-baby-blue);
}

.scrollingNav a {
    text-decoration: none;
    border-radius: 20px;
    background: var(--main-baby-blue);
    padding: 10px;
    font-size: 2rem;

}

.scrollingNav a:hover {
    background-color: var(--main-blue-accent);
}

@media only screen and (max-width: 1100px){
    header .nav-btn {
        visibility: visible;
        opacity: 1;
    }

    .nav{
        position: fixed;
        top:0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 0.8s;
        transform: translateY(-100vh);
        z-index:999;
    }
    .nav ul{
        flex-direction: column;
    }
    header {
        z-index: 999;
    }
    header .responsive_nav{
        transform: none;
        z-index:999;
    }
    .nav-close-btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
    .scrollingNav {
        opacity:0;
    }
}